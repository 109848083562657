import RouterContainer from "./RouterContainer";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { useState } from "react";
import { useEffect } from "react";
import { Circles } from "react-loader-spinner";

function App() {
  const [isloading, setIsloading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setIsloading(false);
    };
    fetchData();
  }, []);

  // #172f5c
  // #4fa94d

  return (
    <div>
      {isloading ? (
        <Circles
          height="50"
          width="50"
          color="#172f5c"
          ariaLabel="circles-loading"
          wrapperStyle={{}}
          wrapperClass="mainLoader"
          visible={true}
        />
      ) : (
        <RouterContainer />
      )}
    </div>
  );
}

export default App;
