import React, { useState } from "react";
import "./WaokoCPC.css";

import PadelBall from "../Assets/Image/CLUB COACH PLAYER ICONS/MAIN LANDING PAGE PADEL/Waoko Club Padel Fiexed Logo.png";
import PadelBallName from "../Assets/Image/CLUB COACH PLAYER ICONS/MAIN LANDING PAGE PADEL/Waoko Club Text.png";

import PadelBall1 from "../Assets/Image/CLUB COACH PLAYER ICONS/MAIN LANDING PAGE PADEL/Waoko Player Padel.png";
import PadelBallName1 from "../Assets/Image/CLUB COACH PLAYER ICONS/MAIN LANDING PAGE PADEL/Waoko Player Text.png";

import PickleBall from "../Assets/Image/CLUB COACH PLAYER ICONS/MAIN LANDING PAGE PADEL/Waoko Coach Padel.png";
import PickleBallName from "../Assets/Image/CLUB COACH PLAYER ICONS/MAIN LANDING PAGE PADEL/Waoko Coach Text.png";

import ballImage from "../Assets/Image/CLUB COACH PLAYER ICONS/MAIN LANDING PAGE PADEL/pade ball icon.png";
import FoxImage from "../Assets/Image/WAOKO CUSTOMER SUPPORT/waoko Face.png";

import WaokoImage from "../Assets/Image/Waoko Grey/Waoko Grey.png";

import { useLocation, useNavigate } from "react-router-dom";
import { Button, Modal, OverlayTrigger } from "react-bootstrap";
import clsx from "clsx";

import { padelbluebg } from "../Assets/base64Images/padelbluebgUrl";
import { padelgreenbg } from "../Assets/base64Images/padelgreenbg";
import { websubtext } from "../Assets/base64Images/websubtext";
import { padelaquabg } from "../Assets/base64Images/padelaquabg";
import { webtext } from "../Assets/base64Images/webtext";

import { OpenAIAPIKey } from "../Confing/Confing";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

export default function WaokoBlue() {
  const [modalShow, setModalShow] = React.useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const [flag, setFlag] = useState(1);

  function onchange() {
    if (location.pathname.includes("pickle")) {
      navigate("/waokblue");
    } else {
      navigate("/pickleblue");
    }
  }
  const handleChange = () => {
    navigate("/waokoGame");
  };

  const [isHovered, setIsHovered] = useState(1);

  const handleMouseEnter = (e) => {
    setIsHovered(e);
  };

  const handleMouseLeave = (e) => {
    setIsHovered(e);
  };



  const tooltip = (
    <div id="tooltip">
      <div className="ballounPadelImag1 pb-4 me-3">
        <div style={{ padding: 30, fontSize: "14px" }}>
          <strong>Holy guacamole!</strong> Check this info. Lorem ipsum dolor
          sit amet consectetur adipisicing elit. Pariatur, repudiandae.
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={clsx([
        isHovered === 1
          ? "PadelDarkAquaImg1"
          : isHovered === 2
          ? "PadelDarkBlueImg1"
          : "PadelDarkGreenImg",
      ])}
      style={{
        backgroundImage: `url(${
          isHovered === 1
            ? padelaquabg
            : isHovered === 2
            ? padelbluebg
            : padelgreenbg
        })`,
      }}
    >
      <div className="centerMainDiv">
        <div className="w-100">
          <div className="d-flex justify-content-center">
            <div
              className="waokoImage"
              style={{ backgroundImage: `url(${webtext})` }}
            ></div>
          </div>

          <div
            data-aos="fade-up"
            data-aos-duration="8000"
            className="WgMaindiv"
          >
            <img src={websubtext} className="waokoImageGame" alt="" />
          </div>

          <div className="mt-3">
            <div className="p-0 m-0 d-flex flex-wrap justify-content-center">
              <div
                className="padeballMain"
                onMouseEnter={() => handleMouseEnter(1)}
                onMouseLeave={() => handleMouseLeave(1)}
              >
                <div>
                  <div className="d-flex justify-content-center">
                    <img src={PadelBall} className="padeBall2" alt="" />
                  </div>
                  <div className="d-flex justify-content-center">
                    <img src={PadelBallName} className="clubBallName" alt="" />
                  </div>
                </div>
              </div>

              <div
                className="padeballMain"
                onMouseEnter={() => handleMouseEnter(2)}
                onMouseLeave={() => handleMouseLeave(2)}
              >
                <div>
                  <div className="d-flex justify-content-center">
                    <img src={PadelBall1} className="padeBall2" alt="" />
                  </div>
                  <div className="d-flex justify-content-center">
                    <img src={PadelBallName1} className="clubBallName" alt="" />
                  </div>
                </div>
              </div>

              <div
                className="pickleballMain"
                onMouseEnter={() => handleMouseEnter(3)}
                onMouseLeave={() => handleMouseLeave(3)}
              >
                <div>
                  <div className="d-flex justify-content-center">
                    <img src={PickleBall} className="padeBall2" alt="" />
                  </div>
                  <div className="d-flex justify-content-center">
                    <img
                      src={PickleBallName}
                      className="coachBallName"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center ps-lg-5 ps-3 pe-lg-5 pe-3 mt-3 pb-3">
            <div onClick={handleChange}>
              <img
                onClick={onchange}
                src={ballImage}
                className="mmr"
                width={100}
                alt=""
              />
            </div>

            <div className="foxImage d-flex justify-content-center">
              <div onClick={() => setModalShow(true)}>
                <img
                  src={FoxImage}
                  width={200}
                  height={170}
                  alt=""
                  style={{ marginLeft: "" }}
                />
                <div className="m-0">
                  <img src={WaokoImage} width={200} alt="" />
                </div>
              </div>
            </div>
            {/* 
          <div className="foxImage d-flex justify-content-center">
            <div>
              <img
                src={FoxImage}
                width={135}
                height={150}
                style={{ marginLeft: "35px" }}
                alt=""
              />
              <div className="m-0">
                <img src={WaokoImage} width={200} alt="" />
              </div>
            </div>
          </div> */}

            <MyVerticallyCenteredModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function MyVerticallyCenteredModal(props) {
  // const [messages, setMessages] = useState([]);
  // const [input, setInput] = useState("");
  // const sendMessage = async () => {
  //   if (input.trim() === "") {
  //     return;
  //   }
  //   const userMessage = { text: input, user: "user" };
  //   setMessages((prevMessages) => [...prevMessages, userMessage]);
  //   try {
  //     const response = await fetchMessage(input);
  //     console.log("OpenAI Response:", response);
  //     setMessages((prevMessages) => [
  //       ...prevMessages,
  //       { text: response, user: "bot" },
  //     ]);
  //   } catch (error) {
  //     console.error("Error fetching message:", error);
  //   }
  // };
  // const fetchMessage = async (input) => {
  //   try {
  //     // const response = await fetch('https://api.openai.com/v1/engines/davinci/completions', {
  //     //     method: 'POST',
  //     //     headers: {
  //     //         'Content-Type': 'application/json',
  //     //         'Authorization': 'Bearer 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',
  //     //     },
  //     //     body: JSON.stringify({
  //     //         prompt: `You: ${input}\nAI:`,
  //     //         max_tokens: 150,
  //     //     })
  //     // });
  //     var myHeaders = new Headers();
  //     myHeaders.append("Content-Type", "application/json");
  //     var raw = JSON.stringify({
  //       text: `${input}`,
  //     });
  //     var requestOptions = {
  //       method: "POST",
  //       headers: myHeaders,
  //       body: raw,
  //       redirect: "follow",
  //     };
  //     const response = await fetch(
  //       "http://127.0.0.1:8000/api/v1/openai/summary",
  //       requestOptions
  //     );
  //     if (!response.ok) {
  //       throw new Error("Failed to fetch response from the server");
  //     }
  //     const result = await response.json();
  //     setInput("");
  //     return result.answer;
  //   } catch (error) {
  //     console.error("Error fetching message:", error);
  //     throw error;
  //   }
  // };
  // return (
  //   <Modal
  //     {...props}
  //     size="md"
  //     aria-labelledby="contained-modal-title-vcenter"
  //     centered
  //     contentClassName="chatbotInput m-3"
  //   >
  //     <div className="chat-container d-flex align-items-start flex-column bd-highlight ">
  //       <div className="w-100">
  //         <Modal.Body>
  //           <div className="message-container">
  //             {messages.map((message, index) => (
  //               <div key={index} className={`message ${message.user}`}>
  //                 {message.text}
  //               </div>
  //             ))}
  //           </div>
  //         </Modal.Body>
  //         <Modal.Footer>
  //           <div className="d-flex align-items-center w-100 mt-auto p-2 bd-highlight">
  //             <input
  //               type="text"
  //               value={input}
  //               onChange={(e) => setInput(e.target.value)}
  //               onKeyPress={(e) => {
  //                 if (e.key === "Enter") {
  //                   e.preventDefault();
  //                   sendMessage();
  //                 }
  //               }}
  //               placeholder="Type a message..."
  //               className="form-control me-3 "
  //             />
  //             <div className="input-group-append ">
  //               <button
  //                 className="btn btn-primary"
  //                 type="button"
  //                 onClick={sendMessage}
  //               >
  //                 <FontAwesomeIcon icon={faPaperPlane} />
  //               </button>
  //             </div>
  //           </div>
  //         </Modal.Footer>
  //       </div>
  //     </div>
  //   </Modal>
  // );
}
