import React, { useState } from 'react'
import './PickleGreen.css'

import WaokoYourGameImg from '../Assets/Image/BACKGROUND COURT SCREEN/Waoko Your Game.png'

import PadelBall from '../Assets/Image/CLUB COACH PLAYER ICONS/MAIN LANDING PAGE PICKLEBALL/Waoko Club Pickleball.png'
import PadelBallName from '../Assets/Image/CLUB COACH PLAYER ICONS/MAIN LANDING PAGE PICKLEBALL/Waoko Club Text.png'

import PadelBall1 from '../Assets/Image/CLUB COACH PLAYER ICONS/MAIN LANDING PAGE PICKLEBALL/Waoko Player Pickleball.png'
import PadelBallName1 from '../Assets/Image/CLUB COACH PLAYER ICONS/MAIN LANDING PAGE PICKLEBALL/Waoko Player Text.png'

import PickleBall from '../Assets/Image/CLUB COACH PLAYER ICONS/MAIN LANDING PAGE PICKLEBALL/Waoko Coach Pickleball.png'
import PickleBallName from '../Assets/Image/CLUB COACH PLAYER ICONS/MAIN LANDING PAGE PICKLEBALL/Waoko Coach Text.png'

import ballImage from '../Assets/Image/CLUB COACH PLAYER ICONS/MAIN LANDING PAGE PADEL/pade ball icon.png'
import FoxImage from '../Assets/Image/WAOKO CUSTOMER SUPPORT/waoko Face.png'

import { useNavigate } from 'react-router-dom'
import { OverlayTrigger } from 'react-bootstrap'
import clsx from 'clsx'

export default function PickleGreen() {

    const navigate = useNavigate()
    const [isFlag, setIsFlag] = useState(1)

    const WaokoCPC = () => {
        // navigate('/pickleaqua')
        navigate('/waokaqua') ? navigate('/picklegreen') : navigate('/waokaqua')
    }

    const tooltip = (
        <div id="tooltip">
            <div className='ballounPadelGreenImag1 pb-4 me-3'>
                <div style={{ padding: 30, fontSize: '14px' }}>
                    <strong>Holy guacamole!</strong> Check this info. Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur, repudiandae.
                </div>
            </div>
        </div>
    );

    return (
        <div className='PadelDarkGreenImgs'>
            <div className='d-flex justify-content-center'>
                <div className="waokoImage"></div>
            </div>

            <div className='WgMaindiv'>
                <img src={WaokoYourGameImg} className='waokoImageGame' alt="" />
            </div>

            <div className='mt-3'>
                <div className='p-0 m-0 d-flex flex-wrap justify-content-center'>
                    <div  className='padeballMain'>
                        <div>
                            <div className='d-flex justify-content-center'>
                                <img src={PadelBall} className='padeBall2' alt="" />
                            </div>
                            <div className='d-flex justify-content-center'>
                                <img src={PadelBallName} className='clubBallName' alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='padeballMain'>
                        <div >
                            <div className='d-flex justify-content-center'>
                                <img src={PadelBall1} className='padeBall2' alt="" />
                            </div>
                            <div className='d-flex justify-content-center'>
                                <img src={PadelBallName1} className='clubBallName' alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='pickleballMain'>
                        <div>
                            <div className='d-flex justify-content-center'>
                                <img src={PickleBall} className='padeBall2' alt="" />
                            </div>
                            <div className='d-flex justify-content-center'>
                                <img src={PickleBallName} className='coachBallName' alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='d-flex justify-content-between align-items-center ps-lg-5 ps-3 pe-lg-5 pe-3 mt-4'>
                <div>
                    <img onClick={WaokoCPC} src={ballImage} width={100} alt="" />
                </div>

                <div className='foxImage'>
                    <OverlayTrigger placement="top" overlay={tooltip}>
                        <div>
                            <img src={FoxImage} width={100} className='ms-lg-4 ms-3' alt="" />
                            <h1 className='text-light waokospace'>WAOKO</h1>
                        </div>
                    </OverlayTrigger>
                </div>
            </div>
        </div>
    )
}
