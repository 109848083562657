import React, { useEffect, useState } from 'react'
import video from "../Assets/Image/Waoko Horizontal 720.mp4"
import { useNavigate } from 'react-router-dom';
import PadelBall from '../Assets/Image/INTRO PAGE/Waoko Padel Ball.png'
import firstimage from '../Assets/Image/BACKGROUND COURT SCREEN/Padel Dark Blue PLAYER.png';
// import {video} from "../Assets/base64Images/images";

export default function AnimationBall() {

    const navigate = useNavigate();

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            navigate('/waokoGame'); 
        }, 5000);
        return () => clearTimeout(timeoutId);

    }, [navigate]);


    return (
        <>
            <video muted autoPlay src={video} className='video-animation' style={{ height: "100vh", width: "100%", objectFit: "fill" }} />
        </>
    )
}
