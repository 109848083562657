import React, { useState } from "react";
import "../PickleBlue/Pickleblue.css";

import PadelBall from "../Assets/Image/CLUB COACH PLAYER ICONS/MAIN LANDING PAGE PICKLEBALL/Waoko Club Pickleball.png";
import PadelBallName from "../Assets/Image/CLUB COACH PLAYER ICONS/MAIN LANDING PAGE PICKLEBALL/Waoko Club Text.png";

import PadelBall1 from "../Assets/Image/CLUB COACH PLAYER ICONS/MAIN LANDING PAGE PICKLEBALL/Waoko Player Pickleball.png";
import PadelBallName1 from "../Assets/Image/CLUB COACH PLAYER ICONS/MAIN LANDING PAGE PICKLEBALL/Waoko Player Text.png";

import PickleBall from "../Assets/Image/CLUB COACH PLAYER ICONS/MAIN LANDING PAGE PICKLEBALL/Waoko Coach Pickleball.png";
import PickleBallName from "../Assets/Image/CLUB COACH PLAYER ICONS/MAIN LANDING PAGE PICKLEBALL/Waoko Coach Text.png";

import ballImage from "../Assets/Image/CLUB COACH PLAYER ICONS/MAIN LANDING PAGE PICKLEBALL/pickleball ball.png";
import FoxImage from "../Assets/Image/WAOKO CUSTOMER SUPPORT/waoko Face.png";

import WaokoImage from "../Assets/Image/Waoko Grey/Waoko Grey.png";

import { useLocation, useNavigate } from "react-router-dom";
import { OverlayTrigger } from "react-bootstrap";
import clsx from "clsx";

import { pickleaquabg } from "../Assets/base64Images/images";
import { picklebluebg } from "../Assets/base64Images/picklebluebg";
import { picklegreenbg } from "../Assets/base64Images/picklegreenbg";

import { webtext } from "../Assets/base64Images/webtext";
import { websubtext } from "../Assets/base64Images/websubtext";

export default function PickleBlue() {
  const navigate = useNavigate();
  const location = useLocation();

  const PickleGreenPage = () => {
    navigate("/picklegreen");
  };

  function onchange() {
    if (location.pathname.includes("pickle")) {
      navigate("/waokoblue");
    } else {
      navigate("/pickleblue");
    }
  }
  const handleChange = () => {
    navigate("/waokoGame");
  };

  const [isHovered, setIsHovered] = useState(1);

  const handleMouseEnter = (e) => {
    setIsHovered(e);
  };

  const handleMouseLeave = (e) => {
    setIsHovered(e);
  };

  const tooltip = (
    <div id="tooltip">
      <div className="ballounPadelImag1 pb-4 me-3">
        <div style={{ padding: 30, fontSize: "14px" }}>
          <strong>Holy guacamole!</strong> Check this info. Lorem ipsum dolor
          sit amet consectetur adipisicing elit. Pariatur, repudiandae.
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={clsx([
        isHovered === 1
          ? "PadelDarkAquaImg1"
          : isHovered === 2
          ? "PadelDarkBlueImg1"
          : "PadelDarkGreenImg",
      ])}
      style={{
        backgroundImage: `url(${
          isHovered === 1
            ? pickleaquabg
            : isHovered === 2
            ? picklebluebg
            : picklegreenbg
        })`,
      }}
    >
      <div className="centerMainDiv">
        <div className="w-100">
          <div className="d-flex justify-content-center">
            <div
              className="waokoImage"
              style={{ backgroundImage: `url(${webtext})` }}
            ></div>
          </div>

          <div className="WgMaindiv">
            <img src={websubtext} className="waokoImageGame" alt="" />
          </div>

          <div className="mt-3">
            <div className="p-0 m-0 d-flex flex-wrap justify-content-center">
              <div
                className="padeballMain"
                onMouseEnter={() => handleMouseEnter(1)}
                onMouseLeave={() => handleMouseLeave(1)}
              >
                <div>
                  <div className="d-flex justify-content-center">
                    <img src={PadelBall} className="padeBall2" alt="" />
                  </div>
                  <div className="d-flex justify-content-center">
                    <img src={PadelBallName} className="clubBallName" alt="" />
                  </div>
                </div>
              </div>
              <div
                className="padeballMain"
                onMouseEnter={() => handleMouseEnter(2)}
                onMouseLeave={() => handleMouseLeave(2)}
              >
                <div>
                  <div className="d-flex justify-content-center">
                    <img src={PadelBall1} className="padeBall2" alt="" />
                  </div>
                  <div className="d-flex justify-content-center">
                    <img src={PadelBallName1} className="clubBallName" alt="" />
                  </div>
                </div>
              </div>
              <div
                className="pickleballMain"
                onMouseEnter={() => handleMouseEnter(3)}
                onMouseLeave={() => handleMouseLeave(3)}
              >
                <div>
                  <div className="d-flex justify-content-center">
                    <img src={PickleBall} className="padeBall2" alt="" />
                  </div>
                  <div className="d-flex justify-content-center">
                    <img
                      src={PickleBallName}
                      className="coachBallName"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center ps-lg-5 ps-3 pe-lg-5 pe-3 mt-3">
            <div onClick={handleChange}>
              <img onClick={onchange} src={ballImage} width={100} alt="" />
            </div>

            <div className="foxImage d-flex justify-content-center">
              <OverlayTrigger placement="top" overlay={tooltip}>
                <div>
                  <img
                    src={FoxImage}
                    width={200}
                    height={170}
                    alt=""
                    style={{ marginLeft: "" }}
                  />
                  <div className="m-0">
                    <img src={WaokoImage} width={200} alt="" />
                  </div>
                </div>
              </OverlayTrigger>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
