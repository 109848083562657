import React, { useEffect, useState } from "react";
import "../WaokoYourGame/WaokoGame.css";

import PadelBall from "../Assets/Image/INTRO PAGE/Waoko Padel Ball.png";
import PadelBallName from "../Assets/Image/INTRO PAGE/image_2024_05_08T11_46_38_909Z (1).png";

import PickleBall from "../Assets/Image/INTRO PAGE/Waoko Ppickleball Ball.png";
import PickleBallName from "../Assets/Image/INTRO PAGE/Waoko Pickleball.png";
import { useNavigate } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";

import { padelbluebg } from "../Assets/base64Images/padelbluebgUrl";
import { websubtext } from "../Assets/base64Images/websubtext";
import { webtext } from "../Assets/base64Images/webtext";

export default function WaokoGame() {
  const navigate = useNavigate();

  const WaokoCPCgmae = () => {
    navigate("/waokoblue");
  };
  const pickleblue = () => {
    navigate("/pickleblue");
  };

  return (
    <div
      className="PadelDarkBlueImg "
      style={{ backgroundImage: `url(${padelbluebg})` }}
    >
      <div className="centerMainDiv">
        <div className="w-100 ">
          <div className="d-flex justify-content-center p-0 m-0">
            {/* <div className="waokoImageess" style={{ backgroundImage: `url(${video})` }}></div> */}
            {/* <img className="waokoImageess" src={WaokoYourGameImg} alt="" /> */}
            <div
              className="waokoImage"
              style={{ backgroundImage: `url(${webtext})` }}
            ></div>
          </div>

          <div className="WgMaindiv mt-4">
            <img src={websubtext} className="waokoImageGame" alt="" />
          </div>

          <div className="mt-5">
            <div className="p-0 m-0 d-flex flex-wrap justify-content-center">
              <div
                onClick={WaokoCPCgmae}
                className="padeballMain"
                style={{ cursor: "pointer" }}
              >
                <div>
                  <div className="d-flex justify-content-center">
                    {/* <img src={padel_big_ball} className='padeBall' alt="" /> */}
                    <img src={PadelBall} className="padeBall" alt="" />
                  </div>
                  <div className="d-flex justify-content-center">
                    <img src={PadelBallName} className="PadeBallName" alt="" />
                  </div>
                </div>
              </div>
              <div onClick={pickleblue} className="pickleballMain">
                <div>
                  <div className="d-flex justify-content-center">
                    <img src={PickleBall} className="padeBall" alt="" />
                  </div>
                  <div className="d-flex justify-content-center">
                    <img
                      src={PickleBallName}
                      className="PickleBallName"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
