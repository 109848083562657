import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Home/Home";
import WaokoGame from "./WaokoYourGame/WaokoGame";
import WaokoGreen from "./Padel Green/WaokoGreen";
import WaokoBlue from "./PadelBlue/WaokoBlue";
import WaokoAquaBg from "./PadelAquaBg/WaokoAquaBg";
import PickleBlue from "./PickleBlue/PickleBlue";
import PickleGreen from "./Pickle Green/PickleGreen";
import PickleAquabg from "./Pickle Aqua/PickleAquabg";
import AnimationBall from "./AnimationBall/AnimationBall";

export default function RouterContainer() {

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AnimationBall />} />
          <Route path="/home" element={<Home />} />
          <Route path="/waokoGame" element={<WaokoGame />} />
          <Route path="/waokoblue" element={<WaokoBlue />} />
          <Route path="/waokogreen" element={<WaokoGreen />} />
          <Route path="/waokaqua" element={<WaokoAquaBg />} />
          <Route path="/pickleblue" element={<PickleBlue />} />
          <Route path="/picklegreen" element={<PickleGreen />} />
          <Route path="/pickleaqua" element={<PickleAquabg />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
