import React from 'react'
// import '../Home/Home.css'

import waokoImage from '../Assets/Image/INTRO PAGE/waoko_main-removebg-preview.png'
import { useNavigate } from 'react-router'

export default function Home() {

    const navigate = useNavigate()

    const waokoNavigate = () => {
        navigate('/waokoGame')
    }

    return (
        <div className='PadelDarkBlueImg'>
            <div className="padeModeset">
                <img onClick={waokoNavigate} src={waokoImage} className='waokoImagemain' alt="" />
            </div>
        </div>
    )
}
